import { render, staticRenderFns } from "./userList.vue?vue&type=template&id=2529b08e&scoped=true&"
import script from "./userList.vue?vue&type=script&lang=js&"
export * from "./userList.vue?vue&type=script&lang=js&"
import style0 from "./userList.vue?vue&type=style&index=0&id=2529b08e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2529b08e",
  null
  
)

export default component.exports